import * as React from 'react';
import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import CustomersService from "../../services/customers.service";
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { CustomListCommandCellWithRemove } from "../../components/GridCustomListCommandCell";

export default function List(props) {

    const { t } = useTranslation();


    const CommandCell = props => (
        <CustomListCommandCellWithRemove
            {...props}
            editField='inEdit'
            remove={remove}
            linkToDetails={'/Customers/' + props.dataItem.customerId + '/Details'}
            isAdmin={true}
        />
    );

    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );

    const [customers, setCustomers] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const remove = (dataItem) => {
        setLoading(true);
        CustomersService.deleteCustomer(dataItem.customerId).then(() => {
            CustomersService.getCutomers()
                .then(response => response.data)
                .then(json => {
                    setCustomers(json.contract);
                    setLoading(false);
                });
        });;

    }


    React.useEffect(() => {

        setLoading(true);

        CustomersService.getCutomers()
            .then(response => response.data)
            .then(json => {
                setCustomers(json.contract);
                setLoading(false);
            });
    }, []);


    const loadingCell = (tdElement, props) => {
        if (props.dataItem[props.field] === undefined) {
            // shows loading cell if no data
            return <td> <span className="k-placeholder-line"></span></td>;
        }

        // default rendering for this cell
        return tdElement;
    };


    return (
        <div>
            {loading && loadingPanel}

            <Grid
                data={customers}
                editField='inEdit'
                total={customers.length}
                scrollable={'none'}
                cellRender={loadingCell}
            >
                <GridToolbar>
                    <div>
                        <Link to={'/Customers/Create'}>
                            <button title={t("Customers_Button_Tooltip_Create")} className="k-button k-primary">
                                &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_Create")} &nbsp;
                        </button>
                        </Link>
                    </div>
                </GridToolbar>
                <Column cell={CommandCell} width="160px" sortable={false} />
                <Column field="name" title={t("Customers_Name_Label")} />
            </Grid>
        </div>
    );
}
