import React from 'react';
import Edit from '../../models/customers/Edit';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CustomerService from "../../services/customers.service";
import AuthService from "../../services/auth.service";

export default function EditCostumers(props) {

    const { t } = useTranslation();
    const { params } = props.match;
    const [customer, setCustomer] = React.useState({
        "customerId": "",
        "name": "",
        "creationDate": Date.now.toJSON,
        "remarks": "",
        "isDisabled": false
    });

    React.useEffect(() => {

        CustomerService.getCustomer(params.customerId)
            .then(response => response.data)
            .then(json => {
                setCustomer({
                    "customerId": json.contract.customerId,
                    "name": json.contract.name,
                    "creationDate": json.contract.creationDate,
                    "remarks": json.contract.remarks,
                    "isDisabled": json.contract.isDisabled
                });

            });
    }, [params.customerId]);

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Customers/'}>{t("Customers_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Customers/' + customer.customerId + '/Details'}>{customer.name}</Link></li>
                </ol>
            </nav>
            <br />

            { AuthService.isUSerAdmin() ?

                <Edit
                    customer={customer}
                />

                :

                <b>{t("Global_NotAllowed_Message")}</b>
            }

            <br />
            <br />
        </div >
    );

}
