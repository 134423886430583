import * as React from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { CustomInput, CustomCheckbox, CustomDropDown } from "../../components/CustomComponents"
import UsersService from "../../services/users.service";
import CustomersService from "../../services/customers.service";
import { useTranslation } from "react-i18next";

export default function Insert(props) {

    const { t } = useTranslation();

    const [domainUsers, setDomainUsers] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {

        UsersService.getDomainUsers()
            .then(response => response.data)
            .then(json => {
                setDomainUsers(json.contract);
                setLoading(false);

            });

        CustomersService.getCutomers()
            .then(response => response.data)
            .then(json => {
                setCustomers(json.contract);
                setLoading(false);

            });
    }, []);


    const [user, setUser] = React.useState({
        "customer": {},
        "userId": "b83eb637-1747-4164-a1c0-ea60ff92eff6",
        "name": "",
        "domainUser": {},
        "isDisabled": false
    });

    const requiredValidator = (value) => {
        return value ? "" : "This field is required";
    }

    const handleSubmit = (dataItem) => {

        UsersService.insertUser({
            "customerId": dataItem.customer.customerId,
            "userId": "b83eb637-1747-4164-a1c0-ea60ff92eff6",
            "userName": dataItem.name,
            "domainUserId": dataItem.domainUser.domainUserId,
            "isDisabled": dataItem.isDisabled
        }).then(end => {
            return window.location.href = "/Users/" + end.data.contract.userId + "/Details";
        }
        );
    }


    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={user}
                key={JSON.stringify(user)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <b>{t("User_Details_Label")}</b>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <Field
                                                label={t("User_Name_Label")}
                                                name={"name"}
                                                loading={loading}
                                                component={CustomInput}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("Customer_IsDisabled_Label")}
                                                name={'isDisabled'}
                                                loading={loading}
                                                component={CustomCheckbox}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <Field
                                                loading={loading}
                                                name="customer"
                                                textField="name"
                                                value={user.customer}
                                                component={CustomDropDown}
                                                dataItemKey="customerId"
                                                style={{ width: "100%" }}
                                                options={customers}
                                                label={t("User_Customer_Label")}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <Field
                                                loading={loading}
                                                name="domainUser"
                                                textField="userName"
                                                value={user.domainUser}
                                                component={CustomDropDown}
                                                dataItemKey="domainUserId"
                                                style={{ width: "100%" }}
                                                options={domainUsers}
                                                label={t("User_DomainUser_Label")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <br />
                        <div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>
                    </FormElement>)}
            />
        </div>
    );
}
