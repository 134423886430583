import React from 'react';
import { Home } from './components/Home';
import { Route, Switch } from "react-router-dom";
import Login from "./components/Login";
import Profile from "./components/Profile";
import ValidationCreate from "./pages/validation/Create"
import ValidationDetails from "./pages/validation/Details"
import ValidationsList from "./pages/validation/List"
import CustomersCreate from "./pages/customers/Add"
import CustomersDetails from "./pages/customers/Details"
import CustomersList from "./pages/customers/List"
import UsersCreate from "./pages/users/Add"
import UsersDetails from "./pages/users/Details"
import UsersList from "./pages/users/List"
import DrawerRouterContainer from './components/DrawerRouterContainer';
import version from './version'
import AuthService from "./services/auth.service";
import './custom.css'
import '@progress/kendo-theme-bootstrap/dist/all.css';

export default function App() {


    if (process.env.NODE_ENV === "production") {
        let user = AuthService.getCurrentUser();
        if (!user)
            window.location.replace('https://auth.agrifoodatsolutions.com?service=AGRF_FL_WEBAPP&solution=AGRF_FL');
    }

    return (
        <div>
            <DrawerRouterContainer>
                <Switch>
                    <Route exact path={["/", "/home"]} component={Home} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/profile" component={Profile} />
                    <Route exact path="/Validations/Create" component={ValidationCreate} />
                    <Route exact path="/Validations/" component={ValidationsList} />
                    <Route exact path="/Validations/:validationRequestId/Details" component={ValidationDetails} />
                    <Route exact path="/Customers/Create" component={CustomersCreate} />
                    <Route exact path="/Customers/" component={CustomersList} />
                    <Route exact path="/Customers/:customerId/Details" component={CustomersDetails} />
                    <Route exact path="/Users/Create" component={UsersCreate} />
                    <Route exact path="/Users/" component={UsersList} />
                    <Route exact path="/Users/:userId/Details" component={UsersDetails} />

                </Switch>
                <div className="text-center p-3">
                    <hr style={{ align: 'center', width: '50%', marginLeft: '25%' }} />
                    <footer> {version} © Feed Labeling {(new Date().getFullYear())} - Agrifood Alternative Technologies, SL &amp; Caedis Integral Solutions, SL </footer>
                </div>

            </DrawerRouterContainer>
        </div>
    );
}

