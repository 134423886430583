import * as React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'

export const GridCustomCommandCell = (props) => {
    const { dataItem } = props;
    const { t } = useTranslation();
    const inEdit = dataItem[props.editField];

    return inEdit ? (
        <td className="k-command-cell">
            <button
                className="k-button k-grid-save-command"
                title={dataItem.isNew ? t("Global_Button_Text_Add") : t("Global_Button_Text_Update")}
                onClick={() => (dataItem.isNew ? props.add(dataItem) : props.update(dataItem))}
            >
                <span className="k-icon k-i-save" />
            </button>
            <button
                className="k-button k-grid-cancel-command"
                title={dataItem.isNew ? t("Global_Button_Text_Discard") : t("Global_Button_Text_Cancel")}
                onClick={() => (dataItem.isNew ? props.discard(dataItem) : props.cancel(dataItem))}
            >
                {dataItem.isNew ? (<span className="k-icon k-i-close" />) : (<span className="k-icon k-i-undo" />)}
            </button>
        </td>
    ) : (
            <td className="k-command-cell">
                <button
                    className="k-primary k-button k-grid-edit-command"
                    title={t("Global_Button_Text_Edit")}
                    onClick={() => props.edit(dataItem)}>
                    <span className="k-icon k-i-edit" />
                </button>
                <button
                    className="k-button k-grid-remove-command"
                    title={t("Global_Button_Text_Remove")}
                    onClick={() => props.remove(dataItem)}
                >
                    <span className="k-icon k-i-close" />
                </button>
            </td>
        );
};
