import React from 'react';
import List from '../../models/users/List';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service";

export default function ListCostumers() {

    const { t } = useTranslation();

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Users/'}>{t("Users_Breadcrumb_Title")}</Link></li>
                </ol>
            </nav>
            <br />
            { AuthService.isUSerAdmin() ?

                <List />

                :

                <b>{t("Global_NotAllowed_Message")}</b>
            }
            <br />
            <br />
        </div >
    );

}
