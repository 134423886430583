import * as React from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { CustomInput, CustomCheckbox, CustomDropDown } from "../../components/CustomComponents"
import UserService from "../../services/users.service";
import CustomerService from "../../services/customers.service";
import { useTranslation } from "react-i18next";

export default function Edit(props) {

    const { t } = useTranslation();

    const [loading, setLoading] = React.useState(true);
    const [customers, setCustomers] = React.useState([]);
    const [domainUsers, setDomainUsers] = React.useState([]);
    const [user, setUser] = React.useState({
        "userId": "",
        "name": "",
        "domainUserId": 0,
        "customerId": "",
        "isDisabled": false
    });

    React.useEffect(() => {

        setUser({
            "userId": props.user.userId,
            "name": props.user.userName,
            "domainUser": (domainUsers.find(group => group.domainUserId === props.user.domainUserId)),
            "customer": (customers.find(group => group.customerId === props.user.customerId)),
            "isDisabled": props.user.isDisabled
        });

        if (customers.length > 0 && domainUsers.length > 0)
            setLoading(false);

    }, [customers, domainUsers]);


    React.useEffect(() => {

        CustomerService.getCutomers()
            .then(response => response.data)
            .then(json => {
                setCustomers(json.contract);
            });
    }, [props.user]);

    React.useEffect(() => {

        UserService.getDomainUsers()
            .then(response => response.data)
            .then(json => {
                setDomainUsers(json.contract);
            });
    }, [props.user]);


    const requiredValidator = (value) => {
        return value ? "" : "This field is required";
    }

    const handleSubmit = (dataItem) => {

        UserService.updateUser(props.user.userId, {
            "userId": props.user.userId,
            "userName": dataItem.name,
            "domainUserId": dataItem.domainUser.domainUserId,
            "customerId": dataItem.customer.customerId,
            "isDisabled": dataItem.isDisabled
        }).then(response => response.data)
            .then(json => {
                setUser({
                    "userId": json.contract.userId,
                    "name": json.contract.userName,
                    "domainUser": (domainUsers.find(group => group.domainUserId === json.contract.domainUserId)),
                    "customer": (customers.find(group => group.customerId === json.contract.customerId)),
                    "isDisabled": json.contract.isDisabled
                });
                setLoading(false);

            });
    }


    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={user}
                key={JSON.stringify(user)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <b>{t("User_Details_Label")}</b>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <Field
                                                label={t("User_Name_Label")}
                                                name={'name'}
                                                loading={loading}
                                                component={CustomInput}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("User_IsDisabled_Label")}
                                                name={'isDisabled'}
                                                loading={loading}
                                                component={CustomCheckbox}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <Field
                                                loading={loading}
                                                name="customer"
                                                textField="name"
                                                value={user.customer}
                                                component={CustomDropDown}
                                                dataItemKey="customerId"
                                                style={{ width: "100%" }}
                                                options={customers}
                                                label={t("User_Customer_Label")}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <Field
                                                loading={loading}
                                                name="domainUser"
                                                textField="userName"
                                                value={user.domainUser}
                                                component={CustomDropDown}
                                                dataItemKey="domainUserId"
                                                style={{ width: "100%" }}
                                                options={domainUsers}
                                                label={t("User_DomainUser_Label")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <br />
                        <div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>
                    </FormElement>)}
            />
        </div>
    );
}
