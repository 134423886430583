import * as React from 'react';
import { Grid, GridColumn as Column, GridToolbar, GridHeaderCell } from '@progress/kendo-react-grid';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { GridCustomCommandCell } from '../../components/GridCustomCommandCell';
import ValidationService from '../../services/validation.service'
import { useHistory } from "react-router-dom";
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { DropDownCustomValidationSelector } from '../../components/DropDownCustomValidationSelector';


export const avaiableValidators = [
    { "id": "RawMaterialExistingValidation", "name": "Exists", "validFor": "rawMaterials" },
    { "id": "RawMaterialRepeatedValidation", "name": "Repeated", "validFor": "rawMaterials" },
    { "id": "RawMaterialAnalyticComponentValidation", "name": "Analytic component", "validFor": "rawMaterials" },
    { "id": "AnalyticalComponentExistingValidation", "name": "Exists", "validFor": "analyticalComponents" },
    { "id": "AnalyticalComponentRepeatedValidation", "name": "Repeated", "validFor": "analyticalComponents" },
    { "id": "AnalyticalComponentSpecieValidation", "name": "Specie", "validFor": "analyticalComponents" },
    { "id": "AdditiveExistingValidation", "name": "Exists", "validFor": "additives" },
    { "id": "AdditiveDescriptionValidation", "name": "Description", "validFor": "additives" },
    { "id": "AdditiveDateValidation", "name": "Date", "validFor": "additives" },
    { "id": "AdditiveFunctionalGroupValidation", "name": "Functional group", "validFor": "additives" }
];

export default function Create(props) {

    const { t } = useTranslation();
    const editField = "inEdit";
    const [languages, setLanguages] = React.useState([]);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: "Loading...", languageId: "" });
    const [rawMaterials, setRawMaterials] = React.useState([]);
    const [additives, setAdditives] = React.useState([]);
    const [anaComp, setAnaComp] = React.useState([]);
    const [rawMaterialsSectionName, setRawMaterialsSectionName] = React.useState("");
    const [additivesSectionName, setAdditivesSectionName] = React.useState("");
    const [analyticalComponentSectionName, setAnalyticalComponentSectionName] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [labelPrevisualitationRawMaterials, setLabelPrevisualitationRawMaterials] = React.useState("");
    const [labelPrevisualitationAnaComp, setLabelPrevisualitationAnaComp] = React.useState("");
    const [labelPrevisualitationAdditives, setLabelPrevisualitationAdditives] = React.useState("");
    const [visibleDialog, SetVisibleDialog] = React.useState(false);
    const [isEmptyDialog, SetIsEmptyDialog] = React.useState(false);
    const [isEmptyValidatorDialog, SetIsEmptyValidatorDialog] = React.useState(false);

    // new calidator selection
    const [validatorAdditiveNames, setValidatorAdditiveNames] = React.useState(false);
    const [validatorRawMaterialNames, setValidatorRawMaterialNames] = React.useState(false);
    const [validatorACNames, setValidatorACNames] = React.useState(false);
    const [validatorACbyRawMaterials, setValidatorACbyRawMaterials] = React.useState(false);


    let history = useHistory();

    React.useEffect(() => {

        var selected = localStorage.getItem("i18nextLng") || "en";
        var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
        setLanguages(avaiableLangList);
        setSelectedLenguage(selectedLangDetails);

    }, []);


    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );


    React.useEffect(() => {
    }, []);

    const removeMaterials = dataItem => {
        setRawMaterials(rawMaterials.filter(item => item.temporalId !== dataItem.temporalId));
    };

    const addMaterials = dataItem => {
        setRawMaterials(rawMaterials.map(item => item.temporalId === dataItem.temporalId ? { ...item, inEdit: false } : item));
    };

    const updateMaterials = dataItem => {
        setRawMaterials(rawMaterials.map(item => (item.temporalId === dataItem.temporalId) ? { ...dataItem, inEdit: false } : item));
    };

    const discardMaterials = dataItem => {
        setRawMaterials(rawMaterials.filter(item => item.temporalId !== dataItem.temporalId));
    };

    const cancelMaterials = dataItem => {
        const itemEdit = rawMaterials.find(item => (item.temporalId === dataItem.temporalId));
        setRawMaterials(rawMaterials.map(itemMap => itemEdit.temporalId == itemMap.temporalId ? { ...itemMap, inEdit: false } : itemMap));
    };

    const enterEditMaterial = dataItem => {
        setRawMaterials(rawMaterials.map(item => (item.temporalId === dataItem.temporalId) ? { ...item, inEdit: true, isNew: false } : item));
    };

    const itemChangeMaterial = event => {
        setRawMaterials(rawMaterials.map(item => item.temporalId === event.dataItem.temporalId ? { ...item, [event.field]: event.value } : item));
    };

    const addNewMaterial = () => {
        const newDataItem = { isNew: true, inEdit: true, resourceText: "", temporalId: Math.floor(Math.random() * 1000) + 1 };

        const data = [newDataItem, ...rawMaterials];

        setRawMaterials(data);
    };

    const removeAdditives = dataItem => {
        setAdditives(additives.filter(record => record.temporalId !== dataItem.temporalId));
    };

    const addAdditives = dataItem => {
        setAdditives(additives.map(item => item.temporalId === dataItem.temporalId ? { ...item, inEdit: false } : item));
    };

    const updateAdditives = dataItem => {
        setAdditives(additives.map(item => (item.temporalId === dataItem.temporalId) ? { ...dataItem, inEdit: false } : item));
    };

    const discardAdditives = dataItem => {
        setAdditives(additives.filter(item => item.temporalId !== dataItem.temporalId));
    };

    const cancelAdditives = dataItem => {
        const itemEdit = additives.find(item => (item.temporalId === dataItem.temporalId));
        setAdditives(additives.map(itemMap => itemEdit.temporalId == itemMap.temporalId ? { ...itemMap, inEdit: false } : itemMap));
    };

    const enterEditAdditives = dataItem => {
        setAdditives(additives.map(item => (item.temporalId === dataItem.temporalId) ? { ...item, inEdit: true, isNew: false } : item));
    };

    const itemChangeAdditive = event => {
        setAdditives(additives.map(item => item.temporalId === event.dataItem.temporalId ? { ...item, [event.field]: event.value } : item));
    };

    const addNewAdditive = () => {
        const newDataItem = { isNew: true, inEdit: true, resourceText: "", temporalId: Math.floor(Math.random() * 1000) + 1 };

        const data = [newDataItem, ...additives];

        setAdditives(data);
    };

    const removeAnalyticComponents = dataItem => {
        setAnaComp(anaComp.filter(item => item.temporalId !== dataItem.temporalId));
    };

    const addAnalyticComponents = dataItem => {
        setAnaComp(anaComp.map(item => item.temporalId === dataItem.temporalId ? { ...item, inEdit: false } : item));
    };

    const updateAnalyticComponents = dataItem => {
        setAnaComp(anaComp.map(item => (item.temporalId === dataItem.temporalId) ? { ...dataItem, inEdit: false } : item));
    };

    const discardAnalyticComponents = dataItem => {
        setAnaComp(anaComp.filter(item => item.temporalId !== dataItem.temporalId));
    };

    const cancelAnalyticComponents = dataItem => {
        const itemEdit = anaComp.find(item => (item.temporalId === dataItem.temporalId));
        setAnaComp(anaComp.map(itemMap => itemEdit.temporalId == itemMap.temporalId ? { ...itemMap, inEdit: false } : itemMap));
    };

    const enterEditAnalyticComponents = dataItem => {
        setAnaComp(anaComp.map(item => (item.temporalId === dataItem.temporalId) ? { ...item, inEdit: true, isNew: false } : item));
    };

    const itemChangeAnalyticComponents = event => {
        setAnaComp(anaComp.map(item => item.temporalId === event.dataItem.temporalId ? { ...item, [event.field]: event.value } : item));
    };

    const addNewAnalyticComponents = () => {
        const newDataItem = { isNew: true, inEdit: true, resourceText: "", temporalId: Math.floor(Math.random() * 1000) + 1 };

        const data = [newDataItem, ...anaComp];

        setAnaComp(data);
    };

    const CommandCellMaterials = props => (
        <GridCustomCommandCell
            {...props}
            edit={enterEditMaterial}
            remove={removeMaterials}
            add={addMaterials}
            discard={discardMaterials}
            update={updateMaterials}
            cancel={cancelMaterials}
            editField={editField}
        />
    );

    const CommandCellAdditives = props => (
        <GridCustomCommandCell
            {...props}
            edit={enterEditAdditives}
            remove={removeAdditives}
            add={addAdditives}
            discard={discardAdditives}
            update={updateAdditives}
            cancel={cancelAdditives}
            editField={editField}
        />
    );

    const CommandCellAnalyticComponents = props => (
        <GridCustomCommandCell
            {...props}
            edit={enterEditAnalyticComponents}
            remove={removeAnalyticComponents}
            add={addAnalyticComponents}
            discard={discardAnalyticComponents}
            update={updateAnalyticComponents}
            cancel={cancelAnalyticComponents}
            editField={editField}
        />
    );

    const submitValidation = () => {


        var rawMaterialList = [];
        var rawAnalyticComponents = [];
        var rawAdditives = [];
        var validators = [];
        // TODO SECTION NAME
        //rawMaterialList.push({ name: rawMaterialsSectionName });
        rawMaterials.forEach(element => rawMaterialList.push({ name: element.resourceText }));
        anaComp.forEach(element => rawAnalyticComponents.push({ name: element.resourceText }));
        additives.forEach(element => rawAdditives.push({ additiveId: element.resourceText, value: element.resourceValue, unit: element.resourceUnits }));


        // validator checkbox => validator string

        if (rawAnalyticComponents.length > 0 && validatorACNames) {
            validators.push("AnalyticalComponentExistingValidation");
        }

        //validatorAC.forEach(element => validators.push(element.id));
        //validators.push("AnalyticalComponentExistingValidation");

        if (rawAdditives.length > 0 && validatorAdditiveNames) {
            validators.push("AdditiveExistingValidation");
        }
        //validatorAdditives.forEach(element => validators.push(element.id));
        //validators.push("AdditiveExistingValidation");

        if (rawMaterialList.length > 0 && validatorRawMaterialNames) {
            validators.push("RawMaterialExistingValidation")
        }
        //validatorRawMaterials.forEach(element => validators.push(element.id));
        //validators.push("RawMaterialExistingValidation");


        ValidationService.tryValidation({

            additives: rawAdditives,
            analyticComponents: rawAnalyticComponents,
            rawMaterials: rawMaterialList,
            validators: validators,
            languageId: selectedLenguage.id

        }).then(
            end => {
                history.push("/Validations/");
            }
        );

    };

    const makePrevisualitation = () => {

        if (additives.length > 0 || rawMaterials.length > 0 || anaComp.length > 0) {

            var rawMaterialList = [];

            rawMaterials.forEach(element => rawMaterialList.push(element.resourceText));


            if (rawMaterialList.length > 0)
                setLabelPrevisualitationRawMaterials(rawMaterialsSectionName + " : " + rawMaterialList.join(", "));

            var rawAnaComp = [];

            anaComp.forEach(element => rawAnaComp.push(element.resourceText));

            if (rawAnaComp.length > 0)
                setLabelPrevisualitationAnaComp(analyticalComponentSectionName + " : " + rawAnaComp.join(", "));

            var rawAdditives = [];


            additives.forEach(element => rawAdditives.push(element.resourceText + "(" + element.resourceValue + " " + element.resourceUnits + ")"));

            if (rawAdditives.length > 0)
                setLabelPrevisualitationAdditives(additivesSectionName + " : " + rawAdditives.join(", "));

            SetVisibleDialog(true);
        }
        else if (!validatorACbyRawMaterials && !validatorACNames && !validatorAdditiveNames && !validatorRawMaterialNames) {
            SetIsEmptyValidatorDialog(true);

        }
        else {
            SetIsEmptyDialog(true);
        }

    };

    const toggleDialog = () => {
        SetVisibleDialog(visibleDialog ? false : true);
    }

    const toggleIsEmptyDialog = () => {
        SetIsEmptyDialog(isEmptyDialog ? false : true);
    }

    const toggleIsEmptyValidatorDialog = () => {
        SetIsEmptyValidatorDialog(isEmptyValidatorDialog ? false : true);
    }

    const handleChangeValidatorRawMaterialNames = (event) => {
        setValidatorRawMaterialNames(!validatorRawMaterialNames);
    }

    const handleChangeValidatorACNames = (event) => {
        setValidatorACNames(event.value);
    }

    const handleChangeValidatorAdditiveNames = (event) => {
        setValidatorAdditiveNames(event.value);
    }

    const handleChangeValidatorACbyRawMaterials = (event) => {
        setValidatorACbyRawMaterials(event.value);
    }


    return (
        <div>
            { loading && loadingPanel}

            { message}

            {visibleDialog && <Dialog title={t("Validation_Confirm_Dialog_Title")} onClose={toggleDialog}>
                <p><h6>{t("Validation_Confirm_Dialog_Message")} :</h6></p>
                <div style={{ backgroundColor: "#ffffe6" }}>
                    {labelPrevisualitationRawMaterials && <div>{labelPrevisualitationRawMaterials}<br /></div>}
                    {labelPrevisualitationAdditives && (<div>{labelPrevisualitationAdditives}<br /></div>)}
                    {labelPrevisualitationAnaComp && <div>{labelPrevisualitationAnaComp}</div>}
                </div>
                <br />
                <p>{t("Validation_Confirm_Dialog_Accept_Message")}</p>
                <DialogActionsBar>
                    <button className="k-button" onClick={toggleDialog}>{t("Global_Label_Text_No")}</button>
                    <button className="k-button" onClick={submitValidation}>{t("Global_Label_Text_Yes")}</button>
                </DialogActionsBar>
            </Dialog>}

            {isEmptyDialog && <Dialog title={t("Validation_EmptyLabel_Dialog_Title")} onClose={toggleIsEmptyDialog}>
                <p style={{ margin: "25px", textAlign: "center" }}>{t("Validation_EmptyLabel_Dialog_Message")}</p>
                <DialogActionsBar>
                    <button className="k-button" onClick={toggleIsEmptyDialog}>{t("Global_Label_Text_Ok")}</button>
                </DialogActionsBar>
            </Dialog>}

            {isEmptyValidatorDialog && <Dialog title={t("Validation_EmptyValidator_Dialog_Title")} onClose={toggleIsEmptyValidatorDialog}>
                <p style={{ margin: "25px", textAlign: "center" }}>{t("Validation_EmptyValidator_Dialog_Message")}</p>
                <DialogActionsBar>
                    <button className="k-button" onClick={toggleIsEmptyValidatorDialog}>{t("Global_Label_Text_Ok")}</button>
                </DialogActionsBar>
            </Dialog>}

            <div className="card card-body bg-light">
                <b>{t("Global_Label_Text_Language")}</b>
                <DropDownList
                    name="language"
                    //loading={loading}
                    textField="name"
                    value={selectedLenguage}
                    dataItemKey="id"
                    style={{ width: '200px' }}
                    onChange={e => setSelectedLenguage(e.value)}
                    data={languages}
                />
            </div>
            <br />
            <PanelBar>
                <PanelBarItem expanded={true} title={t("Validation_Validators_Title")}>
                    <div className="row">
                        <div className="col-sm-1">
                        </div>
                        <div className="col-sm-6">
                            <br />
                            <Checkbox value={validatorRawMaterialNames} onChange={handleChangeValidatorRawMaterialNames} label={t("Validation_RawMaterialNameValidator_Label")} />
                            <br />
                            <Checkbox value={validatorACNames} onChange={handleChangeValidatorACNames} label={t("Validation_AnalyticComponentNameValidator_Label")} />
                            <br />
                            <Checkbox value={validatorAdditiveNames} onChange={handleChangeValidatorAdditiveNames} label={t("Validation_AdditiveNameValidator_Label")} />
                            <br />
                            <Checkbox value={validatorACbyRawMaterials} onChange={handleChangeValidatorACbyRawMaterials} label={t("Validation_ACbyRawMaterialsValidator_Label")} />
                            <br />
                            <br />
                        </div>
                    </div>
                </PanelBarItem>
            </PanelBar>

            <br />

            <PanelBar expandMode={'single'} >
                <PanelBarItem expanded={true} title={t("Validation_RawMaterials_Title")}>
                    <div>
                        <br />
                        <div className="row">
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-6">
                                <Input
                                    name="RawMaterialSectionName"
                                    label={t("Validation_RawMaterials_Input_Title")}
                                    value={rawMaterialsSectionName}
                                    style={{ width: "100%" }}
                                    minLength={2}
                                    onChange={e => setRawMaterialsSectionName(e.value)}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10">
                                <Grid
                                    data={rawMaterials}
                                    editField={editField}
                                    //total={rawMaterials.length}
                                    pageable={false}
                                    scrollable={'none'}
                                    onItemChange={itemChangeMaterial}
                                >
                                    <GridToolbar>
                                        <button
                                            title={t("Validation_RawMaterial_Button_Tooltip_New")}
                                            className="k-button k-primary"
                                            onClick={addNewMaterial} >
                                            &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_New")} &nbsp;
                                    </button>
                                    </GridToolbar >
                                    <Column cell={CommandCellMaterials} width="120px" />
                                    <Column field="resourceText" title={t("Validation_RawMaterial_Label")} />

                                </Grid>
                                <br />
                            </div>
                        </div>
                    </div>
                </PanelBarItem>
                <PanelBarItem title={t("Validation_Additives_Title")} >

                    <div>
                        <br />
                        <div className="row">
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-6">
                                <Input
                                    name="AdditivesSectionName"
                                    label={t("Validation_Additives_Input_Title")}
                                    value={additivesSectionName}
                                    style={{ width: "100%" }}
                                    minLength={2}
                                    onChange={e => setAdditivesSectionName(e.value)}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10">
                                <Grid
                                    data={additives}
                                    editField={editField}
                                    //total={rawMaterials.length}
                                    pageable={false}
                                    scrollable={'none'}
                                    onItemChange={itemChangeAdditive}
                                >
                                    <GridToolbar>
                                        <button
                                            title={t("Validation_Additive_Button_Tooltip_New")}
                                            className="k-button k-primary"
                                            onClick={addNewAdditive} >
                                            &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_New")} &nbsp;
                                    </button>
                                    </GridToolbar >
                                    <Column cell={CommandCellAdditives} width="120px" />
                                    <Column field="resourceText" title={t("Validation_Additive_Label")} />
                                    <Column field="resourceValue" editor="numeric" title={t("Validation_Additive_Value_Label")} />
                                    <Column field="resourceUnits" title={t("Validation_Additive_Units_Label")} />
                                </Grid>
                                <br />
                            </div>
                        </div>
                    </div>

                </PanelBarItem>
                <PanelBarItem title={t("Validation_AnalyticalComponent_Title")} >
                    <div>
                        <br />
                        <div className="row">
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-6">
                                <Input
                                    name="AnalyticalComponentsSectionName"
                                    label={t("Validation_AnalyticalComponents_Input_Title")}
                                    value={analyticalComponentSectionName}
                                    style={{ width: "100%" }}
                                    minLength={2}
                                    onChange={e => setAnalyticalComponentSectionName(e.value)}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10">
                                <Grid
                                    data={anaComp}
                                    editField={editField}
                                    //total={rawMaterials.length}
                                    pageable={false}
                                    scrollable={'none'}
                                    onItemChange={itemChangeAnalyticComponents}
                                >
                                    <GridToolbar>
                                        <button
                                            title={t("Validation_AnalyticComponent_Button_Tooltip_New")}
                                            className="k-button k-primary"
                                            onClick={addNewAnalyticComponents} >
                                            &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_New")} &nbsp;
                                    </button>
                                    </GridToolbar >
                                    <Column cell={CommandCellAnalyticComponents} width="120px" />
                                    <Column field="resourceText" title={t("Validation_AnalyticComponent_Label")} />

                                </Grid>
                                <br />
                            </div>
                        </div>
                    </div>
                </PanelBarItem>
            </PanelBar>


            <br />
            <br />
            <div className="float-sm-right k-form-buttons">
                <button
                    type={'submit'}
                    onClick={makePrevisualitation}
                    className="k-button"
                >
                    <span className="k-icon k-i-save" /> &nbsp; {t("Validation_SubmitValidation_Label")}
                </button>
            </div>
            <br />
            <br />
        </div >
    );

}
