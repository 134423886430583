import * as React from 'react';
import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import ValidationsService from "../../services/validation.service";
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'

export default function List(props) {

    const { t } = useTranslation();

    const CustomEditButton = props => {
        const { dataItem } = props;

        return (<td className="k-command-cell">
            <Link to={'/Validations/' + dataItem.validationId + '/Details'}>
                <button className="k-button k-button-icontext">
                    &nbsp;&nbsp;<span className=" k-icon k-i-zoom" title="Details"></span>&nbsp;
            </button>
            </Link>
        </td>);
    };


    const statusCellGrid = props => {

        const { dataItem, field } = props;
        const dataValue = dataItem[field] === null ? '' : dataItem[field];
        var text = "";
        var icon = "";

        if (dataValue === 1) {
            icon = "k-icon k-i-close";
            text = t("Validation_NonValid_Message");
        }
        else if (dataValue === 2) {
            icon = "k-icon k-i-checkmark";
            text = t("Validation_Valid_Message");
        }
        else if (dataValue === 3) {
            icon = "k-icon k-i-gears";
            text = t("Validation_Processing_Message");
        }
        else {
            icon = "k-icon k-i-warning";
            text = t("Validation_Error_Message");
        }

        return (
            <td style={{ textAlign: "center" }}>
                <span className={icon} title={text} />
            </td>
        );
    }

    const dateCellGrid = props => {

        const { dataItem, field } = props;
        const dataValue = dataItem[field] === null ? '' : dataItem[field];
        var f = new Date(dataValue);


        return (
            <td>
                {  f.getHours() + ":" + f.getMinutes() + " - " + f.toLocaleDateString()}
            </td>
        );
    }

    const CommandCell = props => (
        <CustomEditButton
            {...props}
            editField='inEdit'
        />
    );

    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );

    const [state, setState] = React.useState({ skip: 0, take: 10 });
    const [results, setResults] = React.useState(0);
    const [validations, setValidations] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [sorting, setSorting] = React.useState(null);
    const [sortingBy, setSortingBy] = React.useState("creationDate desc");


    React.useEffect(() => {

        setLoading(true);

        var selected = localStorage.getItem("i18nextLng") || "en";
        var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);

        if (sortingBy != null)
            ValidationsService.getValidationsOrderBy(Math.ceil((state.skip + 1) / 10), state.take, sortingBy)
                .then(response => response.data)
                .then(json => {
                    setValidations(json.contract);
                    setResults(json.totalResults);
                    setLoading(false);
                });
        else
            ValidationsService.getValidations(Math.ceil((state.skip + 1) / 10), state.take)
                .then(response => response.data)
                .then(json => {
                    setValidations(json.contract);
                    setResults(json.totalResults);
                    setLoading(false);
                });
    }, [state, sortingBy]);


    const sortChange = (event) => {

        if (event.sort.length === 0) {
            if (sorting != null) {

                setSorting(null);
                setSortingBy(null);
            }
        }
        else {

            setSorting(event.sort);
            setSortingBy(event.sort[0].field + " " + event.sort[0].dir);
        }

    }


    const pageChange = (event) => {
        setState({ skip: event.page.skip, take: event.page.take });
    }

    const loadingCell = (tdElement, props) => {
        if (props.dataItem[props.field] === undefined) {
            // shows loading cell if no data
            return <td> <span className="k-placeholder-line"></span></td>;
        }

        // default rendering for this cell
        return tdElement;
    };


    return (
        <div>
            {loading && loadingPanel}

            <Grid
                data={validations}
                editField='inEdit'
                skip={state.skip}
                take={state.take}
                total={results}
                pageable={{ buttonCount: 4, pageSizes: [10, 20, 50, 100] }}
                scrollable={'none'}
                onPageChange={pageChange}
                cellRender={loadingCell}
                sort={sorting}
                sortable={{
                    allowUnsort: true,
                    mode: 'single'
                }}
                onSortChange={sortChange}
            >
                <GridToolbar>
                    <div>
                        <Link to={'/Validations/Create'}>
                            <button title={t("Validation_Button_Tooltip_Create")} className="k-button k-primary">
                                &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_Create")} &nbsp;
                        </button>
                        </Link>
                    </div>
                </GridToolbar>
                <Column cell={CommandCell} width="80px" sortable={false} />
                <Column field="status" width="80px" cell={statusCellGrid} title={t("Validation_Status_Label")} />
                <Column field="creationDate" cell={dateCellGrid} title={t("Validation_CreationDate_Label")} />
            </Grid>
        </div>
    );
}
