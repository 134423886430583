import React from 'react';
import { Link } from "react-router-dom";
import AuthService from "../services/auth.service";
import SelectableLanguageDropDown from "./SelectableLanguageDropDown";
import { useTranslation } from "react-i18next";

export default function NavMenu(props) {

    const { t } = useTranslation();
    const { onButtonClick } = props;
    const [currentUser, setCurrentUser] = React.useState(null);

    React.useEffect(() => {

        let user = AuthService.getCurrentUser();

        setCurrentUser(user);

    }, []);

    const logOut = () => {
        AuthService.logout();
    }

    const logIn = (e) => {

        if (process.env.NODE_ENV === "production")
            window.location.replace('https://auth.agrifoodatsolutions.com?service=AGRF_FL_WEBAPP&solution=AGRF_FL');
        else
            window.location.href = '/login';

        e.preventDefault();

    }  

    return (
        <div>
            <nav className="navbar navbar-expand navbar-dark" style={{ backgroundColor: "#0069B4" }}>
                <a className="navbar-brand" onClick={onButtonClick} ><img src="https://cdn.agrifoodatsolutions.com/img/fl_logo_40x40.png" height="40" width="40" alt="Feed Labeling Logo" /></a>

                {currentUser ? (
                    <div className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <SelectableLanguageDropDown />
                        </li>
                        <li className="nav-item">
                            <Link to={"/profile"} className="nav-link" style={{}}>{t("Global_Label_Text_Profile")}</Link>
                        </li>
                        <li className="nav-item">
                            <a href="/login" className="nav-link" onClick={logOut}>{t("Global_Label_Text_Logout")}</a></li>
                    </div>
                ) : (
                        <div className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to={"/login"}  onClick={(e) => logIn(e)} className="nav-link">{t("Global_Label_Text_Login")}</Link>
                            </li>
                        </div>
                    )}
            </nav>
        </div>
    );

}
