import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = 'https://flapi.agrifoodatsolutions.com';
const axiosApiInstanceForValidation = axios.create();

axiosApiInstanceForValidation.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForValidation(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class ValidationService {

    tryValidation(validationContract) {
        return axiosApiInstanceForValidation.post(API_URL + '/v1/labelValidationRequests/', validationContract, { headers: authHeader() });
    }

    getValidation(validationId) {
        return axiosApiInstanceForValidation.get(API_URL + '/v1/labelValidationRequests/' + validationId, { headers: authHeader() });
    }

    getValidationResult(validationId) {
        return axiosApiInstanceForValidation.get(API_URL + '/v1/labelValidationResults/' + validationId, { headers: authHeader() });
    }

    getValidations(PageNumber, PageSize) {
        return axiosApiInstanceForValidation.get(API_URL + '/v1/labelValidationRequests/page?pageNumber=' + PageNumber + "&pageSize=" + PageSize, { headers: authHeader() });
    }
    getValidationsOrderBy(PageNumber, PageSize, OrderBy) {
        return axiosApiInstanceForValidation.get(API_URL + '/v1/labelValidationRequests/page?pageNumber=' + PageNumber + "&pageSize=" + PageSize + "&orderBy=" + OrderBy, { headers: authHeader() });
    }

}

export default new ValidationService();
