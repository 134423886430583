import React from 'react';
import Edit from '../../models/users/Edit';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UserService from "../../services/users.service";
import AuthService from "../../services/auth.service";

export default function EditCostumers(props) {

    const { t } = useTranslation();
    const { params } = props.match;
    const [user, setUser] = React.useState({
        "userId": "",
        "userName": "",
        "domainUserId": 0,
        "customerId": "",
        "isDisabled": false
    });

    React.useEffect(() => {

        UserService.getUser(params.userId)
            .then(response => response.data)
            .then(json => {
                setUser({
                    "userId": json.contract.userId,
                    "userName": json.contract.userName,
                    "domainUserId": json.contract.domainUserId,
                    "customerId": json.contract.customerId,
                    "isDisabled": json.contract.isDisabled
                });
            });

    }, [params.userId]);

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Users/'}>{t("Users_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Users/' + params.userId + '/Details'}>{user.userName}</Link></li>
                </ol>
            </nav>
            <br />
            { AuthService.isUSerAdmin() ?

                <Edit
                    user={user}
                />

                :

                <b>{t("Global_NotAllowed_Message")}</b>
            }

            <br />
            <br />
        </div >
    );

}
