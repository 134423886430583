import * as React from 'react';
import { Input, Checkbox, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { FloatingLabel } from '@progress/kendo-react-labels';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DateInput, DatePicker } from '@progress/kendo-react-dateinputs';
import Skeleton from 'react-loading-skeleton';
import { MaskedTextBox } from '@progress/kendo-react-inputs';

export const CustomInput = ({ loading, label, fieldType, value, ...others }) => {
    return (
        <div>
            {loading ? <Skeleton /> :
                <div>
                    <Input
                        label={label}
                        value={value}
                        type={fieldType}
                        {...others} />
                    <ValidationMessage {...others} />
                </div>
            }
        </div>
    );
};

export const CustomTextArea = ({ loading, disabled, label, fieldType, ...others }) => {
    return (
        <div>
            { loading ? <Skeleton /> : disabled ? <div>
                <MaskedTextBox
                    type={fieldType}
                    label={label}
                    readonly={true}
                    {...others}
                />
            </div> :
                <div>
                    <FloatingLabel
                        className={'k-textarea-container'}
                        editorValue={true}
                        label={label}>
                        <TextArea
                            type={fieldType}
                            {...others} />
                        <ValidationMessage {...others}
                        />
                    </FloatingLabel>
                </div>
            }
        </div>
    );
};

export const CustomIntInput = ({ loading, disabled, fieldType, ...others }) => {
    return (
        <div>
            { loading ? <Skeleton /> :
                <div>
                    <NumericTextBox
                        type={fieldType}
                        {...others} />
                    <ValidationMessage {...others} />
                </div>
            }
        </div>
    );
};


export const CustomDateInput = ({ loading, disabled, label, value, ...others }) => {
    return (
        <div>
            {  loading ? <Skeleton /> : disabled ? <div>
                <MaskedTextBox
                    label={label}
                    readonly={true}
                    value={value.toLocaleDateString()}
                    {...others}
                />
            </div> :
                <div>
                    <DateInput
                        //type={fieldType}
                        label={label}
                        value={value}
                        {...others} />
                    <ValidationMessage {...others} />
                </div>
            }
        </div>
    );
};

export const CustomDatePickerInput = ({ label, ...others }) => {
    return (
        <FloatingLabel label={label} editorPlaceholder={'wide'} style={{ width: '100%' }}>
            <DatePicker
                //type={fieldType}
                //label={label}
                //value={value}
                //formatPlaceholder={'wide'}
                {...others} />
            <ValidationMessage {...others} />
        </FloatingLabel>
    );
};


export const ValidationMessage = ({ loading, valid, visited, validationMessage }) => {
    return (
        <>
            { !valid && visited &&
                (<div className="required">{validationMessage}</div>)}
        </>
    );
}



export const CustomCheckbox = ({ loading, ...props }) => {
    return (
        <div>
            { loading ? <Skeleton /> :
                <div>
                    <br />
                    <Checkbox {...props} />
                    <ValidationMessage {...props} />
                </div>
            }
        </div>
    );
};

export const CustomDropDown = ({ loading, disabled, options, value, ...others }) => {

    return (
        <div>{loading ? <Skeleton /> : disabled ? <div>
            <MaskedTextBox
                readonly={true}
                value={value ? value.name : value}
                {...others}
            />
        </div> :
            <div>
                <DropDownList
                    data={options}
                    disabled={disabled}
                    value={value}
                    {...others} />
                <ValidationMessage {...others} />
            </div>
        }
        </div>
    )
}


export const CustomDropDownOptions = ({ onChange, ...others }) => {

    return (
        <DropDownList
            onChange={onChange}
            {...others} />
    )
}
