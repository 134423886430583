import React from 'react';
import Details from '../../models/validation/Details';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function DetailsValidation(props) {

    const { t } = useTranslation();
    const { params } = props.match;

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Validations/'}>{t("Validation_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item active">{t("Validation_ValidationResult_Breadcrumb_Title")}</li>
                </ol>
            </nav>
            <br />
            <Details
                validationRequestId={params.validationRequestId}
            />
            <br />
            <br />
        </div >
    );

}
