import * as React from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { CustomInput, CustomTextArea, CustomCheckbox } from "../../components/CustomComponents"
import CustomerService from "../../services/customers.service";
import { useTranslation } from "react-i18next";

export default function Insert(props) {

    const { t } = useTranslation();



    const [customer, setCustomer] = React.useState({
        "customerId": "",
        "name": "",
        "creationDate": new Date(),
        "remarks": "",
        "isDisabled": false
    });

    const requiredValidator = (value) => {
        return value ? "" : "This field is required";
    }

    const handleSubmit = (dataItem) => {

        CustomerService.insertCustomer({
            "customerId": '39654b7e-18a5-4024-94ec-62544301492d',
            "name": dataItem.name,
            "creationDate": Date.now.toJSON,
            "remarks": dataItem.remarks,
            "isDisabled": dataItem.isDisabled
        }).then(end => {
            return window.location.href = "/Customers/" + end.data.contract.customerId + "/Details";
        }
        );
    }


    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={customer}
                key={JSON.stringify(customer)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <b>{t("Customer_Details_Label")}</b>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <Field
                                                label={t("Customer_Name_Label")}
                                                name={"name"}
                                                component={CustomInput}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("Customer_IsDisabled_Label")}
                                                name={"isDisabled"}
                                                component={CustomCheckbox}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-9">
                                            <Field
                                                label={t("Customer_Remarks_Label")}
                                                name={"remarks"}
                                                component={CustomTextArea}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <br />
                        <div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>
                    </FormElement>)}
            />
        </div>
    );
}
