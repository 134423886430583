import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = 'https://flapi.agrifoodatsolutions.com';
const axiosApiInstanceForValidation = axios.create();

axiosApiInstanceForValidation.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForValidation(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class CustomersService {

    getCutomers() {
        return axiosApiInstanceForValidation.get(API_URL + '/v1/customers', { headers: authHeader() });
    }

    getCustomer(CustomerId) {
        return axiosApiInstanceForValidation.get(API_URL + '/v1/customers/' + CustomerId, { headers: authHeader() });
    }

    deleteCustomer(CustomerId) {
        return axiosApiInstanceForValidation.delete(API_URL + '/v1/customers/' + CustomerId, { headers: authHeader() });
    }

    updateCustomer(CustomerId, Customer) {
        return axiosApiInstanceForValidation.put(API_URL + '/v1/customers/' + CustomerId, Customer, { headers: authHeader() });
    }

    insertCustomer(Customer) {
        return axiosApiInstanceForValidation.post(API_URL + '/v1/customers', Customer, { headers: authHeader() });
    }
}

export default new CustomersService();
