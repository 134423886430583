import * as React from 'react';
import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import UsersService from "../../services/users.service";
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { CustomListCommandCellWithRemove } from "../../components/GridCustomListCommandCell";

export default function List(props) {

    const { t } = useTranslation();

    const CommandCell = props => (
        <CustomListCommandCellWithRemove
            {...props}
            editField='inEdit'
            remove={remove}
            linkToDetails={'/Users/' + props.dataItem.userId + '/Details'}
            isAdmin={true}
        />
    );

    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );

    const [users, setUsers] = React.useState([]);
    const [loading, setLoading] = React.useState(true);


    const remove = (dataItem) => {
        setLoading(true);
        UsersService.deleteUser(dataItem.userId).then(() => {
            UsersService.getUsers()
                .then(response => response.data)
                .then(json => {
                    setUsers(json.contract);
                    setLoading(false);
                });
        });;

    }

    React.useEffect(() => {

        setLoading(true);

        UsersService.getUsers()
            .then(response => response.data)
            .then(json => {
                setUsers(json.contract);
                setLoading(false);
            });
    }, []);


    const loadingCell = (tdElement, props) => {
        if (props.dataItem[props.field] === undefined) {
            // shows loading cell if no data
            return <td> <span className="k-placeholder-line"></span></td>;
        }

        // default rendering for this cell
        return tdElement;
    };


    return (
        <div>
            {loading && loadingPanel}

            <Grid
                data={users}
                editField='inEdit'
                total={users.length}
                scrollable={'none'}
                cellRender={loadingCell}
            >
                <GridToolbar>
                    <div>
                        <Link to={'/Users/Create'}>
                            <button title={t("Users_Button_Tooltip_Create")} className="k-button k-primary">
                                &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_Create")} &nbsp;
                        </button>
                        </Link>
                    </div>
                </GridToolbar>
                <Column cell={CommandCell} width="160px" sortable={false} />
                <Column field="userName" title={t("Users_Name_Label")} />
            </Grid>
        </div>
    );
}
