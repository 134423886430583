import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = 'https://flapi.agrifoodatsolutions.com';
const axiosApiInstanceForValidation = axios.create();

axiosApiInstanceForValidation.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForValidation(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class UsersService {

    getDomainUsers() {
        return axiosApiInstanceForValidation.get(API_URL + '/v1/domainUsers/', { headers: authHeader() });
    }

    getUser(UserId) {
        return axiosApiInstanceForValidation.get(API_URL + '/v1/users/' + UserId, { headers: authHeader() });
    }

    deleteUser(UserId) {
        return axiosApiInstanceForValidation.delete(API_URL + '/v1/users/' + UserId, { headers: authHeader() });
    }

    updateUser(UserId, User) {
        return axiosApiInstanceForValidation.put(API_URL + '/v1/users/' + UserId, User, { headers: authHeader() });
    }

    insertUser(User) {
        return axiosApiInstanceForValidation.post(API_URL + '/v1/users', User, { headers: authHeader() });
    }

    getUsers() {
        return axiosApiInstanceForValidation.get(API_URL + '/v1/users', { headers: authHeader() });
    }

}

export default new UsersService();
