import React, { Component } from "react";
import AuthService from "../services/auth.service";
import jwt_decode from "jwt-decode";

export default class Profile extends Component {
    render() {

        return (
            <div className="container">
                <br />
                <strong>Username:</strong>{" "}{jwt_decode(AuthService.getCurrentUser()).username}
                <br />
                <strong>User ID:</strong>{" "}{jwt_decode(AuthService.getCurrentUser()).userid}
                <br />
                <strong>Solution Code:</strong>{" "}{jwt_decode(AuthService.getCurrentUser()).solutioncode}
                <br />
                <strong>Application Code:</strong>{" "}{jwt_decode(AuthService.getCurrentUser()).applicationcode}
                <br />
                <strong>Components allowed:</strong>{" "}{jwt_decode(AuthService.getCurrentUser()).components}
                <br />
            </div>
        );
    }
}
