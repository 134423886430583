import * as React from 'react';
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'
import ValidationService from '../../services/validation.service'
import Skeleton from 'react-loading-skeleton';

export default function Details(props) {

    const { t } = useTranslation();
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: "Loading...", languageId: "" });
    const [message, setMessage] = React.useState("");

    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [result, setResult] = React.useState("");

    React.useEffect(() => {

        var selected = localStorage.getItem("i18nextLng") || "en";
        var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
        setSelectedLenguage(selectedLangDetails);

    }, []);


    React.useEffect(() => {

        ValidationService.getValidationResult(props.validationRequestId)
            .then(response => response.data)
            .then(json => {

                if (json.contract.status === "Failed")
                    setError(json.contract.status);

                setMessage(json.contract.status);
                setLoading(false);
                setResult(json.contract.result);
            });

    }, [props.validationRequestId]);



    return (
        <div>
            {loading ? <Skeleton /> : (error ? (<div className="alert alert-danger" role="alert" > { message} </div>) : (<div className="alert alert-primary" role="alert"> {message} </div>))}
            {loading ? <Skeleton /> : (result ? (<div className="jumbotron"> {result} </div>) : (<span />))}
        </div>
    );

}
